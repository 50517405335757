import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import ApiServiceClass from '../../classes/api-service.class';
import { User } from '../../interfaces/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { UserSettings } from './user-settings.service';
import { tap } from 'rxjs/operators';
import { Vehicle } from '../../interfaces/vehicle';
import { VehicleGroup } from '../../interfaces/group';
import { Driver } from '../../interfaces/driver';
import * as Raven from 'raven-js';

@Injectable({
  providedIn: 'root'
})
export class UserService extends ApiServiceClass<User> {
  private userSub: BehaviorSubject<User> = new BehaviorSubject(null);
  private userRes: User;
  private settingsSub: BehaviorSubject<UserSettings> = new BehaviorSubject(null);

  constructor(config: ConfigService,
              private authService: AuthService,
              http: HttpClient) {
    super('/users', config, http);
    this.init();
  }

  get user$() {
    return this.userSub.asObservable();
  }

  get settings$(): Observable<UserSettings> {
    return this.settingsSub.asObservable();
  }

  init() {
    this.authService.isLoggedIn$.subscribe((isLogged) => {
      if (isLogged) {
        this.getUser();
        this.getUserSettings();
      } else {
        this.userSub.next(null);
        this.settingsSub.next(null);
      }
    });
  }

  getUserSettings() {
    this.http.get<UserSettings>(`${this.baseUrl}/me/settings`).subscribe((data: UserSettings) => {
      this.settingsSub.next(data);
    });
  }

  getUser() {
    this.http.get<User>(`${this.baseUrl}/me`).subscribe((data: User) => {
      this.userRes = data;
      Raven.setUserContext({
        username: this.userRes.login,
        email: this.userRes.email,
        id: this.userRes.id
      });
      this.updateUserSub();
    });
  }

  updateUserSub() {
    this.userSub.next((Object.assign({}, this.userRes) as User));
  }

  updateSettings(data: Partial<UserSettings>) {
    return this.http.patch<UserSettings>(`${this.baseUrl}/me/settings`, data)
      .pipe(tap(res => this.settingsSub.next(res)));
  }

  vehicles(id: string) {
    return this.http.get<Vehicle[]>(`${this.baseUrl}/${id}/vehicles`);
  }

  vehiclesSync(id: string, vehicleIds = []) {
    return this.http.post(`${this.baseUrl}/${id}/vehicles`, {vehicleIds});
  }

  roles(id: string) {
    return this.http.get<Vehicle[]>(`${this.baseUrl}/${id}/roles`);
  }

  rolesSync(id: string, roleIds = []) {
    return this.http.post(`${this.baseUrl}/${id}/roles`, {roleIds});
  }

  vehiclesGroups(id: string) {
    return this.http.get<VehicleGroup[]>(`${this.baseUrl}/${id}/vehicles-groups`);
  }

  vehiclesGroupsSync(id: string, vehicleGroupIds = []) {
    return this.http.post(`${this.baseUrl}/${id}/vehicles-groups`, {vehicleGroupIds});
  }

  drivers(id: string) {
    return this.http.get<Driver[]>(`${this.baseUrl}/${id}/drivers`);
  }

  driversSync(id: string, driverIds = []) {
    return this.http.post(`${this.baseUrl}/${id}/drivers`, {driverIds});
  }

  driversGroups(id: string) {
    return this.http.get<Vehicle[]>(`${this.baseUrl}/${id}/drivers-groups`);
  }

  driversGroupsSync(id: string, driverGroupIds = []) {
    return this.http.post(`${this.baseUrl}/${id}/drivers-groups`, {driverGroupIds});
  }
}
