import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { RouteHistoryTableRowRideComponent } from './route-history-table-row-ride.component';
import { WarehouseService } from '../../../../core/services/warehouse.service';
import { FuelType } from 'src/app/interfaces/vehicle';

@Component({
  selector: '[app-route-history-table-row-park]',
  templateUrl: './route-history-table-row-park.component.html',
  styleUrls: ['./route-history-table-row-ride.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteHistoryTableRowParkComponent extends RouteHistoryTableRowRideComponent implements OnInit {
  @Input() vehicleFuelType: FuelType;
  @HostBinding('class.is-override') get isOverride() {
    return this.data && this.data.RIDE_IS_OVERRIDE && this.data.RIDE_IS_OVERRIDE[0].v;
  }

  constructor(warehouseService: WarehouseService) {
    super(warehouseService);
  }

  ngOnInit() {
  }

  get parkStatus() {
    return this.warehouseService.parkStatus(this.data);
  }

  goToLink(url: string, event){
    window.open(url, '_blank');
    event.stopPropagation();
}
}
