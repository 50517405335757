<td class="dates-cell" colspan="2">
    <div class="d-flex justify-content-start align-items-center">
        <div class="status-label--wrapper">
            <app-vehicle-status-label size="sm"
                                      [status]="parkStatus"
                                      [ignition]="data.PARK_ENGINE_ON[0].v"></app-vehicle-status-label>
        </div>
        <app-from-to showType="hours" [from]="data.PARK_STARTDATE[0].v"
                     [to]="data.PARK_ENDDATE[0].v">
        </app-from-to>
    </div>
</td>
<td class="localization-row">
    <div>
        <app-localization type="park" [data]="data" [ellipsis]="true"></app-localization>
    </div>
    <div *ngIf="data.PARK_POD.length> 0">
      <ng-container *ngFor="let podItem of data.PARK_POD" >
        <div class="pod-link" (click)="goToLink(podItem.v[0].url, $event)" *ngIf="podItem.v && podItem.v.length > 0 && podItem.v[0].url">
        </div>
      </ng-container>
    </div>
</td>
<td class="work-type">
    <app-status-bar [max]="120" [value]="data.SUM_PARK_DURATION[0].v">
        {{data.SUM_PARK_DURATION[0]|warehouseUnit}}
    </app-status-bar>
</td>
<td class="work-type work-type--time"></td>
<td></td>
<td >
  <app-status-bar [value]="data.SUM_PARK_FUEL_CHANGE_LITRES[0].v" *ngIf="data.SUM_PARK_FUEL_CHANGE_LITRES[0].v > 0" >
    <app-icon-bg iconClass="icon-petrol" label="sum"></app-icon-bg>
    {{data.SUM_PARK_FUEL_CHANGE_LITRES[0]|warehouseUnit:1:true}} {{vehicleFuelType|fuelUnit}}
</app-status-bar>
</td>
<td class="hide-by-theme">
</td>
<td>
    <app-badge color="maya" [value]="data.SUM_CUSTOMER_WORK_PARK_COUNT|warehouseValue" [showIfZero]="false"
               size="lg"></app-badge>
</td>
<td class="drivers-row">

</td>
<td></td>

