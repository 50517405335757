<table class="table c-history-table w-100">
    <thead>
    <tr app-table-header [isDriver]="isDriver" [vehicleFuelType]="vehicleFuelType" [fuelDimmension]="fuelDimmension"></tr>
    </thead>
    <tr *ngIf="aggregationSum" app-route-history-table-row [data]="aggregationSum"
        [isSelected]="selected === aggregationSum.ID[0].v"
        [isDriver]="isDriver"
        [fuelDimmension]="fuelDimmension"
        [vehicleFuelType]="vehicleFuelType"
        (click)="handlerShowAllRide()" [aggregationRow]="true"></tr>
    <tr class="c-history-table__tr-inner" *ngIf="collection && collection.length">
        <td colspan="12">
            <div heightFromTop class="c-history-table__inner-wrapper">
                <perfect-scrollbar [config]="configPS" class="ps-at-right">

                    <table class="w-100 table">
                        <tr style="visibility: collapse;height: 0px" app-table-header>
                        </tr>
                        <ng-container *ngFor="let item of collection; let i = index">

                            <tr app-route-history-table-row [data]="item.start"
                                (rollover)="rolloverToggle(item.start.ID[0].v)"
                                [isOpen]="activeRowId === item.start.ID[0].v"
                                [isDriver]="isDriver"
                                [isSelected]="selected === item.start.ID[0].v"
                                (click)="handlerShowDailyRide(item)"
                                [fuelDimmension]="fuelDimmension"
                                [vehicleFuelType]="vehicleFuelType"
                            ></tr>
                            <tr
                                    class="day-row">
                                <td colspan="12" class="c-rollover-row__cell p-0">
                                    <div class="c-rollover-row__wrapper">
                                        <div class="c-rollover-row" *ngIf="activeRowId === item.start.ID[0].v" [@slideInOut]>
                                            <table class="table table-days w-100">
                                                <tr style="visibility: collapse;height: 0px" app-table-header [isDriver]="isDriver">
                                                </tr>
                                                <ng-container *ngFor="let dayItem of item.days">
                                                    <tr
                                                            (click)="handlerShowOneRide(dayItem)"
                                                            app-route-history-table-row-ride
                                                            [data]="dayItem"
                                                            [isDriver]="isDriver"
                                                            [isSelected]="selected ===dayItem.ID[0].v && !isParkSelected"
                                                            [fuelDimmension]="fuelDimmension"
                                                    ></tr>
                                                    <tr *ngIf="dayItem.PARK_STARTDATE && dayItem.PARK_STARTDATE[0].v"
                                                        (click)="handlerShowOneRide(dayItem, true)"
                                                        [isSelected]="selected === dayItem.ID[0].v && isParkSelected"
                                                        app-route-history-table-row-park
                                                        [vehicleFuelType]="vehicleFuelType"
                                                        [data]="dayItem"
                                                    ></tr>
                                                </ng-container>
                                            </table>

                                        </div>
                                    </div>
                                </td>


                            </tr>
                        </ng-container>
                    </table>
                </perfect-scrollbar>
            </div>
        </td>
    </tr>
</table>
<div class="c-no-result" *ngIf="collection && collection.length === 0">Brak jazd w wybranym okresie</div>
