<div class="d-flex justify-content-between align-items-center">
  <div class="flex-grow-1 ">
    <app-route-filters (filterChange)="filtersChange($event)" filterId="vehicle_history">
        <app-date-range-filter (changeRange)="dateFilterChange($event)" [date]="dateFilters"
                              [eventOnInit]="false"></app-date-range-filter>
    </app-route-filters>
  </div>
  <div class="ml-s action-bar">
    <button class="btn btn-primary ml-s btn-icon hide-by-theme" (click)="generateReport()" translate>
          <i class="icon-reports"></i>
    </button>
  </div>
</div>
<div class="scroll-wrapper position-relative pt-0">
    <app-route-history-table
            [aggregationSum]="aggregationSum"
            [collection]="collection"
            (showAggregationSum)="handlerShowAllRide($event)"
            (showDaily)="handlerDaily($event)"
            [isParkSelected]="isParkSelected"
            [isDriver]="!!driver"
            [vehicleFuelType]="vehicle ? vehicle.fuelType : null"
            [initActiveRow]="activeRow"
            [selected]="selectedRideData?.ID[0].v"
            [noCacheExtensions]="vehicle ? vehicle.noCacheExtensions : null"
    ></app-route-history-table>
    <div class="map-contener" *ngIf="showMap" [@ngIfEnter]="showMap">
        <app-route-history-map
                [rideTelemetry]="rideTelemetry"
                [fuelTelemetry]="fuelTelemetry"
                [markers]="markers"
                [fuelLimits]="fuelLimits"
                [zoom]="mapZoom"
                [modulo]="indexModulo"
                [polylineMarkers]="polylineMarkers"
                [selectedRideData]="selectedRideData"
                [speedMarkers]="speedMarkers"
                (closeMap)="closeMap()"
                (polyLoaded)="polyLoaded($event)"
                [vehicle]="vehicle"
        ></app-route-history-map>
    </div>
</div>
<app-overflow-spinner [showSpinner]="pending"></app-overflow-spinner>



