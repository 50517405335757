import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { WorkVehicleWarehouse } from '../../../../core/services/vehicles.service';
import { WarehouseService } from '../../../../core/services/warehouse.service';

@Component({
  selector: '[app-route-history-table-row-ride]',
  templateUrl: './route-history-table-row-ride.component.html',
  styleUrls: ['./route-history-table-row-ride.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteHistoryTableRowRideComponent {
  @Input() data: WorkVehicleWarehouse;
  @HostBinding('class.is-selected')
  @Input() isSelected = false;
  @Input() isDriver = false;
  @Input() fuelDimmension = 'DISTANCE';

  constructor(protected warehouseService: WarehouseService) {

  }

  get rideStatus() {
    return this.warehouseService.rideStatus(this.data);
  }
}
